import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser as logout, refreshUserToken as refreshToken } from '../../store/auth/actions';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import { AccountType } from '../../helpers/enum/accounts';

const TIME_INTERVAL_NORMAL = 15000; // 15 seconds
const TIME_INTERVAL_FAST = 1000; // 1 second
const WARNING_TIME = 60; // Countdown duration in seconds
const FAST_CHECK_START = 75; // 1 minute 15 seconds remaining

function TokenExpiry() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.currentUser);
  const [remainingTime, setRemainingTime] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const intervalRef = useRef(null);

  const tokenDuration = user ? (user?.account_type_id === AccountType?.USER ? 60 * 60 : 60 * 60 * 4) : 0; //1 hour for users, 4 hours for admins

  const getSessionStart = () => {
    const sessionStart = localStorage.getItem('sessionStart');
    return sessionStart ? new Date(sessionStart).getTime() : null;
  };

  const checkSessionStart = () => {
    const sessionStart = getSessionStart();
    if (!sessionStart || !isAuthenticated) return;

    const currentTime = Date.now();
    const elapsedTime = Math.floor((currentTime - sessionStart) / 1000); // Elapsed time in seconds
    const timeRemaining = Math.max(tokenDuration - elapsedTime, 0); // Total session duration = 2 minutes

    setRemainingTime(timeRemaining);

    if (timeRemaining <= WARNING_TIME) {
      setShowModal(true);
      setSeconds(timeRemaining);
    } else {
      setShowModal(false);
      setSeconds(null);
    }
  };

  const _onClickExtend = () => {
    dispatch(refreshToken());
    setShowModal(false);
    setSeconds(null);
  };

  const _logout = () => {
    clearInterval(intervalRef.current);
    setShowModal(false);
    setSeconds(null);
    dispatch(logout());
  };

  const _onClickCancel = () => {
    _logout();
  };

  useEffect(() => {
    const startInterval = () => {
      clearInterval(intervalRef.current);

      const interval = remainingTime <= FAST_CHECK_START ? TIME_INTERVAL_FAST : TIME_INTERVAL_NORMAL;

      intervalRef.current = setInterval(checkSessionStart, interval);
    };

    startInterval();
    return () => clearInterval(intervalRef.current);
  }, [remainingTime]);

  useEffect(() => {
    checkSessionStart();
  }, [isAuthenticated]);

  useEffect(() => {
    if (showModal && seconds !== null && seconds <= 0) {
      _logout(); // Automatically logout when countdown ends
      return;
    }

    if (showModal) {
      const countdownTimer = setTimeout(() => setSeconds(seconds - 1), TIME_INTERVAL_FAST);
      return () => clearTimeout(countdownTimer);
    }
  }, [seconds, showModal]);

  return (
    <SimpleAlert
      show={showModal}
      errorTitle="Token about to expire"
      errorMsg={`Your session will expire soon. You have ${seconds} seconds remaining to renew your token.`}
      onAcceptClick={_onClickExtend}
      onDeclineClick={_onClickCancel}
      onAcceptText="Extend"
      onDeclineText="Log out"
    />
  );
}

export default TokenExpiry;
