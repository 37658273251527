import { useState, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import { lineItemTypesV2 } from '../../helpers/enum/lineItemTypes';
import Loader from '../../components/Loading/Loader';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import formatCurrency from '../../utils/formatCurrency';
import formatNumber from '../../utils/formatNumber';
import PopOutMenu from '../../components/Menus/PopOutMenu';
import { format } from 'date-fns';
import { ExclamationIcon } from '@heroicons/react/outline';
import Toggle from '../../components/Toggles/Toggle';
import paidStatus from '../../helpers/enum/paidStatus';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';

const InvoicePreviewAndTable = ({ id, onSaveLine }) => {
  const [lineItems, setLineItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [optionalComments, setOptionalComments] = useState(null);
  const [isAMatch, setIsAMatch] = useState(true);
  const [approved, setApproved] = useState(null);
  const [canApprove, setCanApprove] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [totals, setTotals] = useState({
    totalDue: '0',
    totalVat: 'n/a',
    totalDuePlusVat: '0',
  });
  const {
    accountsPayable: { getAccountsPayableById },
  } = useApi();

  useEffect(() => {
    if (!id) return;
    getAccountsPayableById(id).then(res => {
      setIsSaving(false);
      let _lineItems = [];
      if (res?.lineItems?.length) {
        _lineItems = res.lineItems.map(li => li);
      }
      setTotals({
        totalDue: res?.cost,
        totalVat: res?.vat,
        totalDuePlusVat: res?.cost_and_vat,
      });
      setIsLoading(false);
      setLineItems(_lineItems);
      setIsAMatch(res?.amount_match);
      setApproved(res?.approved);
      setOptionalComments(res.comments?.length ? res.comments : null);
      setCanApprove(
        res?.paid !== paidStatus.numbers.unpaid ||
          _lineItems.find(li => li.type === lineItemTypesV2.accountsPayable.numbers.unapprovedProjectAllocations)
          ? {
              bool: false,
              reason:
                res?.paid !== paidStatus.numbers.unpaid
                  ? 'Invoice had been partially or fully paid.'
                  : _lineItems.find(li => li.type === lineItemTypesV2.accountsPayable.numbers.unapprovedProjectAllocations) &&
                    "Timesheet(s) must be approved before approving the user's invoice.",
            }
          : { bool: true },
      );
    });
  }, [id]);

  const onApproveAPClick = () => {
    setApproved(!approved);
    setCanSave(!canSave);
  };

  const onClickSave = () => {
    //FIXME: Should we keep this isSaving feature? It will make the button load but usually this save only takes a fraction of a second
    setIsSaving(true);
    onSaveLine({ id, approved: !approved });
  };

  return (
    <Loader isLoading={isLoading} className="my-4">
      <div className="mt-8 mb-4 flex flex-col h-full">
        <div className="mb-4 w-full flex justify-between items-center">
          <div className="flex gap-x-3 items-center">
            <span className=" text-lg font-medium leading-6 text-gray-900">Invoice lines</span>
            {!isAMatch && (
              <div className="flex gap-x-1 text-thaleria-orange-600">
                <ExclamationIcon className="h-6 w-6" />
                <p>User's invoice doesn't match lines</p>
              </div>
            )}
            {optionalComments && (
              <span>
                [
                <PopOutMenu
                  key="comments"
                  title={'Comments'}
                  className="h-52"
                  openButton={<p className="text-blue-700 hover:text-blue-500">view comments</p>}
                >
                  <ul className="divide-y divide-gray-200 pr-4 h-40 overflow-y-auto">
                    {optionalComments
                      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                      .map(obj => {
                        return (
                          <li className="py-2 ">
                            <span className="flex items-center w-full justify-between">
                              <p className="font-medium text-sm ">{obj.user.first_names}</p>
                              <p className="font-medium text-sm ">{format(new Date(obj.created_at), 'dd/MM/yy - HH:mm')}</p>
                            </span>
                            <p className="px-2 py-1 inline-flex text-sm leading-5 text-gray-400">{obj.comment}</p>
                          </li>
                        );
                      })}
                  </ul>
                </PopOutMenu>
                ]
              </span>
            )}
          </div>
          {onSaveLine && (
            <div className="">
              <Toggle
                label="Approved"
                enable={approved}
                onChange={() => onApproveAPClick()}
                className="flex-row-reverse justify-start mr-1"
                disabled={!canApprove.bool}
                tooltip={!canApprove.bool ? canApprove.reason : null}
              />
            </div>
          )}
        </div>
        <div className={` px-2 border border-gray-300 rounded-md`}>
          <table className="table-auto w-full h-max mt-2 px-4">
            <thead className="w-full">
              <tr className="text-md ">
                <th className="text-center">Purchase order</th>
                <th className="text-center">Type</th>
                <th className="text-center">Units</th>
                <th className="text-center">UoM</th>
                <th className="text-center">Price</th>
                <th className="text-center">VAT rate</th>
                <th className="text-center">Total Excl VAT</th>
                <th className="text-center">VAT</th>
                <th className="text-center">Total Incl VAT</th>
              </tr>
            </thead>
            <tbody className="p-2 h-max w-full text-sm">
              {lineItems?.length ? (
                lineItems.map((li, i) => {
                  return (
                    <tr className="border-dashed my-1 h-8" key={li?.id || li?.uuid}>
                      {li.ts_entry_adjustment ? (
                        <td className="text-center">
                          <div className="flex items-center justify-center">
                            {li.staffOrder.order_ref}
                            <InfoTooltip size="sm">{li.description}</InfoTooltip>
                          </div>
                        </td>
                      ) : (
                        <td className="text-center">{li?.staffOrder?.order_ref || ''}</td>
                      )}
                      <td className="text-center">{lineItemTypesV2.accountsPayable.strings[li.type]}</td>
                      <td className="text-center">{formatNumber(li.units, true, 6)}</td>
                      <td className="text-center">{li.uom}</td>
                      <td className="text-center">{formatCurrency(li.price)}</td>
                      <td className="text-center">{formatNumber(li.vat_rate, true) + '%'}</td>
                      <td className="text-center">{formatCurrency(li.total)}</td>
                      <td className="text-center">{formatCurrency(li.vat)}</td>
                      <td className="text-center relative">{formatCurrency(li.total_and_vat)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>No allocations found</tr>
              )}
            </tbody>
            <tfoot className="border-t border-gray-300 pt-2 h-8 text-sm w-full">
              <tr>
                <td className="font-semibold text-center text-base">Grand total</td>
                <td className=""></td>
                <td className=""></td>
                <td className=""></td>
                <td className=""></td>
                <td className="font-bold text-thaleria-blue-500 text-center "></td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalDue)}</td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalVat)}</td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalDuePlusVat)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {canSave && (
        <div className="w-full flex justify-end mt-6">
          <ButtonPrimary text="Save" onClick={() => onClickSave()} />
        </div>
      )}
    </Loader>
  );
};

export default InvoicePreviewAndTable;
