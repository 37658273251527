import axios from 'axios';
import { combineReducers, createStore } from 'redux';

import { setNotification } from './app/actions';
import notifications from '../helpers/enum/notifications';
import { API_BASE_URL } from '../configs';

import { registerStore } from './registerStore';
import middlewares from './middlewares';
import { getCurrentUser, logOutUser } from './auth/actions';
import authReducer from './auth/reducer';
import appReducer from './app/reducer';
import { LOGOUT_USER } from './auth/types';

export default function initStore() {
  const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
  });

  const store = createStore(rootReducer, middlewares);

  axios.defaults.baseURL = API_BASE_URL;
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    response => response,
    error => {
      const { response } = error;

      if (response && response.status !== 403) {
        store.dispatch(
          setNotification(
            notifications.WARNING_YELLOW,
            error.response?.data?.error?.type || 'Server Error',
            error.response?.data?.error?.message || 'An error occurred. Please try again later.',
          ),
        );
      }
      if (response && response.status === 403) {
        store.dispatch({
          type: LOGOUT_USER,
          payload: {},
        });
      }
      return Promise.reject(error);
    },
  );

  store.dispatch(getCurrentUser());

  registerStore(store);

  return store;
}
