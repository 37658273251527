import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../../containers/ServerSideTable';
import useApi from '../../../../hooks/useApi';
import AdminSidebar from '../../AdminSidebar';
import { americanDate } from '../../../../helpers/date';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import { format } from 'date-fns';
import UpdateLeaveBalanceModal from '../../../../containers/LeaveDaysLog/UpdateLeaveBalanceModal';
import * as leaveBalanceLogTypes from '../../../../helpers/enum/leaveBalanceLogTypes';
import TableTabs from '../../../../components/TableItems/FakeTableTabs';
import { useHistory, useLocation } from 'react-router-dom';

function LeaveDaysLog() {
  const [entries, setEntries] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [totalPages, setTotalPages] = useState();
  const [filterOptions, setFilterOptions] = useState({});
  const [showUpdateLeaveBalance, setShowUpdateLeaveBalance] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const {
    leaveDaysBalanceLog: { getLeaveDaysLogPaginated, getFilterOptions },
  } = useApi();

  const fetchData = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    const users = filters.find(filter => filter.id === 'user')?.value;
    const creators = filters.find(filter => filter.id === 'createdBy')?.value;
    const creationDates = filters.find(filter => filter.id === 'createdAt')?.value;
    const comment = filters.find(filter => filter.id === 'comment')?.value;
    const recuperationDays = filters.find(filter => filter.id === 'recuperationDays')?.value;
    const leaveDays = filters.find(filter => filter.id === 'leaveDays')?.value;
    const leaveRequests = filters.find(filter => filter.id === 'leaveRequests')?.value;
    const staffContracts = filters.find(filter => filter.id === 'staffContract')?.value;
    const types = filters.find(filter => filter.id === 'type')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    let dates;

    if (creationDates !== undefined) {
      dates = creationDates.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }

    if (downloadToCsv) {
      let csvData = [];

      await getLeaveDaysLogPaginated(
        page + 1,
        users,
        creators,
        dates,
        comment,
        recuperationDays,
        leaveDays,
        staffContracts,
        leaveRequests,
        types,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      ).then(res => {
        res.forEach(entry => {
          csvData.push({
            user: `${entry?.user?.first_names} ${entry?.user?.last_names}`,
            createdBy: `${entry?.created_by?.first_names} ${entry?.created_by?.last_names}`,
            createdAt: entry?.created_at ? format(new Date(entry?.created_at), 'dd/MM/yy') : '-',
            leaveDays: entry.leave_days_balance,
            recuperationDays: entry.recuperation_days_balance,
            comment: entry.comment,
            staffContract: entry.staff_contract.contract_ref,
            leaveRequestId: entry?.leave_request_id || '-',
            type: entry?.type ? leaveBalanceLogTypes.strings[entry.type] : '-',
          });
        });

        return csvData;
      });
    } else {
      getLeaveDaysLogPaginated(
        page + 1,
        users,
        creators,
        dates,
        comment,
        recuperationDays,
        leaveDays,
        staffContracts,
        leaveRequests,
        types,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      ).then(res => {
        setEntries(res.rows);
        setTotalPages(res.totalPages);
        setTotalItems(res.totalItems);
      });
    }
  };

  useEffect(() => {
    //fetch filter options for users w/ timesheets + max and min period
    getFilterOptions().then(d => {
      setFilterOptions(d);
    });
  }, []);

  const memoizedTableData = useMemo(() => {
    const tableData = [];
    if (entries && entries?.length) {
      entries.forEach(entry => {
        tableData.push({
          user: `${entry?.user?.first_names} ${entry?.user?.last_names}`,
          createdBy: `${entry?.created_by?.first_names} ${entry?.created_by?.last_names}`,
          createdAt: entry?.created_at ? format(new Date(entry?.created_at), 'dd/MM/yy') : '-',
          leaveDays: entry.leave_days_balance,
          recuperationDays: entry.recuperation_days_balance,
          comment: entry.comment,
          staffContract: entry.staff_contract.contract_ref,
          leaveRequestId: entry?.leave_request_id || '-',
          type: entry?.type ? leaveBalanceLogTypes.strings[entry.type] : '-',
        });
      });
    }
    return tableData;
  }, [entries]);

  const Tooltip = ({ value, position }) => {
    const [tooltipStyle, setTooltipStyle] = useState({});

    React.useEffect(() => {
      const tooltipWidth = 320; // Adjust max-width
      const tooltipHeight = 100; // Estimate max height
      const padding = 10;

      const { x, y } = position;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let adjustedX = x + padding;
      let adjustedY = y + padding;

      // Adjust if tooltip exceeds right boundary
      if (x + tooltipWidth + padding > windowWidth) {
        adjustedX = x - tooltipWidth - padding;
      }

      // Adjust if tooltip exceeds bottom boundary
      if (y + tooltipHeight + padding > windowHeight) {
        adjustedY = y - tooltipHeight - padding;
      }

      setTooltipStyle({
        top: `${adjustedY}px`,
        left: `${adjustedX}px`,
        maxWidth: '20rem', // Tailwind max-w-80
        position: 'absolute',
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        padding: '0.5rem',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        whiteSpace: 'normal',
      });
    }, [position]);

    return <div style={tooltipStyle}>{value}</div>;
  };

  const CommentCell = ({ value }) => {
    const [hovered, setHovered] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = e => {
      const { clientX, clientY } = e;
      setHovered(true);
      setTooltipPosition({ x: clientX, y: clientY });
    };

    const handleMouseMove = e => {
      const { clientX, clientY } = e;
      setTooltipPosition({ x: clientX, y: clientY });
    };

    const handleMouseLeave = () => {
      setHovered(false);
    };

    return (
      <div
        className="w-80 truncate cursor-default"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {value}
        {hovered && <Tooltip value={value} position={tooltipPosition} />}
      </div>
    );
  };

  let columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions?.users?.length
          ? filterOptions?.users?.map(u => ({
              value: u?.id,
              label: `${u?.first_names} ${u?.last_names}`,
            }))
          : [],
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions?.creators?.length
          ? filterOptions?.creators?.map(u => ({
              value: u?.id,
              label: `${u?.first_names} ${u?.last_names}`,
            }))
          : [],
      },
      {
        Header: 'Created on',
        accessor: 'createdAt',
        Filter: DateRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Leave days',
        accessor: 'leaveDays',
      },
      {
        Header: 'Recuperation days',
        accessor: 'recuperationDays',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: Object.keys(leaveBalanceLogTypes.numbers).map(key => ({
          value: key,
          label: leaveBalanceLogTypes.numbers[key],
        })),
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        Cell: ({ value }) => <CommentCell value={value} />,
      },
      {
        Header: 'Staff contract',
        accessor: 'staffContract',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions?.staffContracts?.length
          ? filterOptions?.staffContracts?.map(u => ({
              value: u?.id,
              label: u?.contract_ref,
            }))
          : [],
      },
      {
        Header: 'Leave request id',
        accessor: 'leaveRequestId',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions?.leaveRequests?.length
          ? filterOptions.leaveRequests.map(l => ({ value: l.leave_request_id, label: l.leave_request_id }))
          : [],
      },
    ],
    [filterOptions],
  );

  const pages = [
    { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: false },
    { name: 'Leave days log', href: '/admin-panel/hr/leave-days-log', current: false },
  ];

  const tabs = [
    {
      name: 'Leave requests',
      href: '/admin-panel/hr/leave-requests',
      onClick: () =>
        !location.pathname.includes('/admin-panel/hr/leave-requests') && history.push('/admin-panel/hr/leave-requests'),
    },
    {
      name: 'Leave balance log',
      href: '/admin-panel/hr/leave-balance-log',
      onClick: () =>
        !location.pathname.includes('/admin-panel/hr/leave-balance-log') && history.push('/admin-panel/hr/leave-balance-log'),
    },
  ];

  return (
    <AdminSidebar noPadding pages={pages}>
      <TableTabs tabs={tabs} />
      <UpdateLeaveBalanceModal open={showUpdateLeaveBalance} setOpen={setShowUpdateLeaveBalance} fetchData={fetchData} />
      <Table
        columns={columns}
        data={memoizedTableData}
        tableName="leaveDaysLog"
        pageCount={totalPages}
        totalItems={totalItems}
        fetchData={fetchData}
        addButton={{ onClick: () => setShowUpdateLeaveBalance(true) }}
      />
    </AdminSidebar>
  );
}

export default LeaveDaysLog;
