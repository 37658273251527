import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import ReactSelect from '../../components/Inputs/ReactSelect';
import TextArea from '../../components/Inputs/TextArea';
import Modal from '../../components/Modals/Modal';
import countryID from '../../helpers/enum/CountryIDs';
import notifications from '../../helpers/enum/notifications';
import useApi from '../../hooks/useApi';
import { setNotification } from '../../store/app/actions';
import InputSimple from '../../components/Inputs/InputSimple';
import { PlusIcon, ArrowRightIcon } from '@heroicons/react/solid';
import formatNumber from '../../utils/formatNumber';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import * as contractTypes from '../../helpers/enum/contractTypeIDs';

function UpdateLeaveBalanceModal({ open, setOpen, fetchData }) {
  const dispatch = useDispatch();
  const {
    leaveRequests: { updateLeaveRequestBalance, getUserAvailableLeave },
    users: { getAllUsers },
    staffContracts: { getUserStaffContracts },
  } = useApi();

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);

  const [paidLeaveToAdd, setPaidLeaveToAdd] = useState();
  const [recuperationToAdd, setRecuperationToAdd] = useState();
  const [newPaidLeaveDays, setNewPaidLeaveDays] = useState();
  const [newRecuperationDays, setNewRecuperationDays] = useState();
  const [updateMessage, setUpdateMessage] = useState('');
  const [textError, setTextError] = useState('');
  const [currentLeaveDays, setCurrentLeaveDays] = useState({});
  const [userOptions, setUserOptions] = useState([]);
  const [staffContractOptions, setStaffContractOptions] = useState([]);

  useEffect(() => {
    getAllUsers().then(res => {
      setUserOptions(
        res.map(el => ({
          value: el.id,
          label: el.full_name,
        })),
      );
    });
  }, []);

  useEffect(() => {
    const _newPaidLeaveDays = (currentLeaveDays?.paidLeaveDays || 0) + (Number(paidLeaveToAdd) || 0);
    const _newRecuperationDays = (currentLeaveDays?.recuperationDays || 0) + (Number(recuperationToAdd) || 0);
    setNewPaidLeaveDays(formatNumber(_newPaidLeaveDays));
    setNewRecuperationDays(formatNumber(_newRecuperationDays));
  }, [paidLeaveToAdd, recuperationToAdd]);

  const updateLeaveBalanceHandler = () => {
    if (newRecuperationDays === currentLeaveDays.recuperationDays && newPaidLeaveDays === currentLeaveDays.paidLeaveDays) {
      handleCloseModal();
      return;
    } else if (!updateMessage) {
      setTextError('Message must be filled');
    } else {
      const dataToSend = {
        recuperation_days_balance: newRecuperationDays,
        leave_days_balance: newPaidLeaveDays,
        comment: updateMessage,
        staff_contract_id: selectedContract.value,
      };

      updateLeaveRequestBalance(selectedUser.value, dataToSend).then(() => {
        dispatch(setNotification(notifications.SUCCESS, 'Success', 'Leave request balance successfully updated.'));
        fetchData();
        handleCloseModal();
      });
    }
  };

  const handleCloseModal = () => {
    setTextError('');
    setOpen(false);
    setSelectedUser(null);
    setSelectedContract(null);
  };

  const handleUserChange = user => {
    if (user) {
      getUserStaffContracts(user.value).then(res => {
        setStaffContractOptions(
          res
            .filter(sc => sc.contract_type_id === contractTypes.employee)
            .map(sc => ({
              value: sc.id,
              label: sc.contract_ref,
              countryId: sc.country_id,
            })),
        );
      });
    } else {
      setStaffContractOptions([]);
    }
    setSelectedUser(user);
    setSelectedContract(null); // Reset contract selection when user changes
  };

  const handleContractChange = contract => {
    if (!contract) {
      setCurrentLeaveDays({});
      return setSelectedContract(null);
    }
    getUserAvailableLeave(selectedUser.value, contract.value).then(res => {
      setCurrentLeaveDays({
        paidLeaveDays: res?.leave_days_balance,
        recuperationDays: res?.recuperation_days_balance,
      });
      setSelectedContract(contract);
    });
  };

  return (
    <Modal title="Update leave days" show={open} hide={() => handleCloseModal()}>
      <div className="bg-white flex flex-col items-center w-full">
        <div className="flex flex-col gap-y-4 w-full">
          <ReactSelect isSearchable label="Select User" options={userOptions} onChange={handleUserChange} value={selectedUser} />
          <ReactSelect
            isSearchable
            options={staffContractOptions}
            onChange={handleContractChange}
            value={selectedContract}
            label="Select Staff Contract"
            error={selectedUser && !staffContractOptions?.length && 'User has no employee contracts'}
          />
        </div>

        {selectedContract && (
          <div className="space-y-4 w-full mt-4">
            <div>Leave days</div>
            <div className="w-full flex items-center justify-start space-x-4">
              <p>{currentLeaveDays?.paidLeaveDays || 0}</p>
              <PlusIcon className="w-4 h-4 text-gray-500" />
              <div className="w-20">
                <InputSimple type="number" value={paidLeaveToAdd} onChange={e => setPaidLeaveToAdd(e.target.value)} />
              </div>
              <ArrowRightIcon className="w-4 h-4 text-gray-500" />
              <p>{newPaidLeaveDays}</p>
            </div>

            <div>Recuperation days</div>
            <div className="w-full flex items-center justify-start space-x-4">
              <p>{currentLeaveDays?.recuperationDays || 0}</p>
              <PlusIcon className="w-4 h-4 text-gray-500" />
              <div className="w-20">
                <InputSimple
                  type="number"
                  value={recuperationToAdd}
                  onChange={e => setRecuperationToAdd(e.target.value)}
                  disabled={selectedContract.countryId !== countryID.belgium}
                />
              </div>
              <ArrowRightIcon className="w-4 h-4 text-gray-500" />
              <p>{newRecuperationDays}</p>
            </div>

            <TextArea
              errorMessage={textError}
              label="Message"
              rows={3}
              value={updateMessage}
              onChange={e => setUpdateMessage(e.target.value)}
            />

            <div className="flex flex-row-reverse w-full mt-6">
              <ButtonPrimary style="ml-2" text="Update" onClick={updateLeaveBalanceHandler} />
              <ButtonWhite text="Cancel" onClick={handleCloseModal} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UpdateLeaveBalanceModal;
