const leaveBalanceLogTypes = {
  strings: {
    1: 'Yearly update',
    2: 'Monthly update',
    3: 'Initial balance',
    4: 'LR approval',
    5: 'LR cancellation',
    6: 'Custom',
  },
  numbers: {
    yearlyUpdate: 1,
    monthlyUpdate: 2,
    initialBalance: 3,
    LRApproval: 4,
    LRCancellation: 5,
    custom: 6,
  },
};

module.exports = leaveBalanceLogTypes;
